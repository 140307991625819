import '../styles/header.css'

export default function Header(){
    return(
        <div className="header-container">            
             <div className='h1-container'>
                <h1 className='header-h1'>Welcome To My Portfolio!</h1>
            </div>  
        </div>
    )
}